export * from "./StringsContext";
export const strings = {
    en: {
        pageHeader: "Create an escalation",
        pageSubHeader: "Select escalation team or issue type",
        mycasesSearchPlaceHolder: "Search by customer name, ID, escalation number or other keyword",
        consumerTitle: "Home User",
        consumerDescription: "<ul><li>Non-commercial cases</li><li>Devices (Xbox, Surface, etc.)</li><li>Home User Technical Problems</li><li>Personal Accounts and Billing</li><li>Compromised account, etc.</li></ul>",
        consumerLinkName: "Select",
        consumerLink: "/escalation/consumer",
        licenseTitle: "LicenseQ",
        licenseDescription: "Pre-sales and Audit scenario questions to clarify Microsofts Product Licensing and Volumn Licensing program terms.",
        licenseLinkName: "Select",
        licenseLink: "/escalation/licenseq",
        commercialTitle: "Commerce, Volume Licensing and Post Sales Seller Support",
        commercialDescription: "<ul><li>Post Sales Seller Support Team Engagement</li><li>Volume Licensing</li><li>Commerce (Tenant related items)</li><li>Online Services Provisioning</li><li>Invoice Adjustment/Credit Services</li></ul>",
        commercialLinkName: "Select",
        commercialLink: "/escalation/pssst",
        executiveTitle: "Executive Customer Relations",
        executiveDescription: "<ul><li>ExecHot</li><li>Legal</li><li>Press</li></ul>",
        executiveLinkName: "Select",
        executiveLink: "/escalation/ecr",
        technicalProductTitle: "Technical Product Issues",
        technicalDescription: "<ul><li>Support case traction</li><li>Engineering/PG exceptions</li><li>Cloud service reliability and confidence call requests</li><li>D365 Legal/Compensation</li><li>Azure Quota, etc.</li></ul>",
        technicalLinkName: "Go to GetHelp",
        technicalLink: "/escalation/technicalproductissues",
        partnerProgramTitle: "Customer or Partner Programs",
        partnerProgramDescription: "<ul><li>Co-Sell</li><li>CSP</li><li>MAICPP</li><li>Marketplace,etc.</li></ul>",
        partnerProgramLinkName: "Go to GetHelp",
        partnerProgramLink: "/escalation/cpp",
        privacyTitle: "Privacy-Compliance-Audit",
        privacyDescription: "<ul><li>Data access, location, processing and transfers (incl. EUDB) </li><li>Questions related to Data Protection Addendum (DPA) and Data Protection Impact Assessment (DPIA) </li><li>Usage of Sub processors and third-parties </li><li>Audits, Compliance and Service Trust Portal questions (ISO, HIPAA, NIST, Business Continuity, etc) </li></ul>",
        privacyLinkName: "Select",
        privacyLink: "/escalation/pca",
        specialprogramTitle: "Non-Product Security Incident-Topic & Other Specialty Escalations",
        specialprogramDescription: "<ul><li>Security Topic: Midnight Blizzard, SFI, etc.</li><li>VVIP (Restricted to CMET)</li><li>Consumption Blocker (Restricted to Customer Success)</li><li>Critical Customer Issue/Risk (CCI/R)</li><li>And Others Not Listed</li></ul>",
        specialprogramLinkName: "Select",
        specialprogramLink: "/escalation/specialprograms",
        spcialProgramSubTitle: "Non-Product Security Incident-Topic & Other Specialty Escalations",
        myEscalationsHeader: "GetHelp 2.0",
        myEscalationsSubHeader: "Track your escalations and advocate on behalf of your customers",
        caseTileLinkName: "View Details",
        myescalationsLink: "/myescalations",
        //Request Labels
        requestComplete: "Request Complete",
        requestConflict: "This item has been changed by someone else since you opened it. You will need to refresh it and discard your changes.",
        requestFail: "Request Failed",
        requestPending: "Request Pending",
        requestRefreshListView: "Request Completed. Refresh the page to see all the latest records.",
        //Escalation Create Page Info Bar
        escalationInfoMessage: "For Public Sector and Government customers, a previous Service Request number is required in order to comply with Microsoft’s standards of business conduct guidelines.",
        //Escalation form - labels
        submitterLabel: "Who are you submitting on behalf of?",
        pcasubmitterLabel: "Who is submitting this escalation?",
        submitterTimezoneLabel: "Submitter preferred time zone",
        customerLabel: "Customer",
        customerFirstNameLabel: "Customer first name",
        customerLastNameLabel: "Customer last name",
        firstNameLabel: "First name",
        lastNameLabel: "Last name",
        customerLocationLabel: "Customer location",
        haveCustomerEmailLabel: "I have customer email",
        customerEmailLabel: "Contact email",
        customerPhoneLabel: "Customer phone",
        emailLabel: "Email",
        phoneLabel: "Phone",
        customerPreferredLanguageLabel: "Customer preferred language",
        microsoftAccountIdLabel: "Microsoft account ID",
        escalationSourceLabel: "Escalation source",
        issueTypeLabel: "Issue type",
        productFamilyLabel: "Product family",
        issueDescriptionLabel: "Why is this issue being escalated?",
        programLabel: "Program",
        supportRequestNumberLabel: "Support request number",
        attachmentsLabel: "Attachments",
        licenseQReasonToBypass: "Reasons to Bypass LicenseQ Knowledge Portal",
        partnerLabel: "Partner",
        partnerLocationLabel: "Partner Location",
        requiredField: "This is a required field",
        confirmationStatement: "I confirm that the information provided is accurate, does not contain any sensitive information, and is in compliance with Microsoft's policies including supporting Public Sector customers. Account information will not be provided to third parties, including but not limited to legal entities, who do not own the impacted MSAs.",
        serviceInterruptionOutageLabel: "Is this escalation related to a Service Interruption outage?",
        requestRelatedLabel: "What is this request related to?",
        purchasingChannelLabel: "Purchasing Channel",
        desiredOutcomeLabel: "Desired Outcome",
        businessImpactLabel: "Business Impact",
        securityPrivacyThreatLabel: "Security and Privacy Threat",
        complianceCertificationLabel: "Compliance/Certification",
        affectedProductslabel: "Affected products",
        affectedProductLabel: "Affected Product",
        affectedProgramLabel: "Affected Program",
        additionalAffectedProductslabel: "Additional affected products",
        programslabel: "Programs",
        parentProductLabel: "Parent Product",
        componentLabel: "Component",
        additionalAffectedProgramslabel: "Additional affected programs",
        agreementNumberLabel: "Agreement/enrollment number",
        tenantTypeLabel: "Tenant Type",
        tenantIdsLabel: "Tenant Ids",
        tenantNameLabel: "Tenant Name",
        tenantFieldQuestionnaire: "My customer does not have a tenant/Escalation not related to online services",
        subscriptionTypeLabel: "Azure subscription type",
        subscriptionIDLabel: "Azure subcription ID",
        associatedIdsLabel: "Associated tickets or IDs",
        incidentNumberLabel: "Service Incident Number",
        associatedIcmSrReferenceLabel: "Associated IcM/SR reference",
        engagementIDLabel: "Engagement ID",
        opportunityIDLabel: "Opportunity ID",
        opportunityIDHelpText: "Providing the Opportunity ID will help ensure the ask is accurately tracked against customer commitments thus supporting prioritization",
        additionalInternalStakeholdersLabel: "Additional Internal Stakeholders",
        additionalInternalStakeholdersDLsLabel: "Additional Stakeholders Groups/DL",
        issueDescriptionAdditionalContentLabel: "Why is this issue being escalated? \nWhat steps have been taken so far (including results)?",
        //Escalation form - placeholders
        submitterPH: "Search Microsoft alias or email",
        desiredOutcomePH: "1-3 sentences summarizing the issue and why the escalation is needed",
        customerSearchPH: "Select one of your customers or use advanced search",
        customerFirstNamePH: "Enter customer first name",
        customerLastNamePH: "Enter customer last name",
        firstNamePH: "Enter first name",
        lastNamePH: "Enter last name",
        customerLocationPH: "Select customer location from the list",
        partnerLocationPH: "Select partner location from the list",
        customerEmailPH: "Enter customer contact email",
        emailPH: "Enter contact email",
        phonePH: "Enter phone number",
        microsoftAccountIdPH: "Enter Microsoft Account ID",
        programPH: "Select program",
        issueTypePH: "Select issue type from the list",
        productFamilyPH: "Select product family from the list",
        parentProductPH: "Select parent product",
        componentPH: "Select Component",
        issueDescriptionPH: "Please describe the issues customer is facing",
        timezonePH: "Select preferred time zone",
        TenantIdsPH: "Add a Tenant ID",
        supportRequestNumberPH: "Add support request number",
        purchasingChannelPH: "Select purchasing channel from the list",
        affectedProductsPH: "Select affected products from the list",
        affectedProductPH: "Select affected product from the list",
        affectedProgramPH: "Select affected program from the list",
        additionalAffectedProductsPH: "Select additional affected products from the list",
        affectedProgramsPH: "Select affected programs from the list",
        additionalAffectedProgramsPH: "Select additional affected programs from the list",
        agreementNumberPH: "Add an enrollment number",
        tenantNamePH: "Add a tenant name",
        subscriptionTypePH: "Select subscription type",
        subscriptionIDPH: "Enter subscription ID",
        incidentNumberPH: "Enter Service Incident Number",
        associatedIcmSrReferencePH: "Enter Associated IcM or SR reference",
        requestRelatedPH: "Select what this request is related to",
        businessRiskPH: "What is the risk to Microsoft's business? \nIf users are impacted, then how? \nWhat is the impact to customer's business? \nIs Microsoft or customer revenue impacted or at risk?",
        permittedFileExtensions: ".bmp, .csv, .doc, .docx, .eml, .gif, .gz, .htm, .html, .jpg, .mht, .mhtm, .mhtml, .msg, .odf, .odt, .pdf, .png, .ppt, .pptx, .page, .rar, .rtf, .tif, .tiff, .txt, .vsd, .vsdx, .xls, .xlsb, .xlsx, .xml, .wpd, .wps, .zip",
        spBusinessRiskPH: "What is the risk to Microsoft's business? \nIf users are impacted, then how? \nWhat is the impact to customer's business? \nIs Microsoft or customer revenue impacted or at risk?",
        // Form Validation error messages
        customerLocationEM: "Please select a valid country",
        customerFirstNameEM: "Please enter a valid first name",
        selectProgram: "Please enter a valid program",
        customerLastNameEM: "Please enter a valid last name",
        customerEmailEM: "Please enter a valid email address",
        customerPhoneEM: "Please enter a valid phone number",
        timezoneEM: "Please select a valid timezone",
        purchasingChannelEM: "Please select a valid purchasing channel",
        escalationSourceEM: "Please select a valid Escalation Source",
        issueDescriptionEM: "Please enter a valid issue description",
        businessImpactEM: "Please enter a valid business impact",
        desiredOutcomeEM: "Please enter a valid desired outcome",
        tenantIdsEM: "Please enter a valid Tenant ID",
        tenantNameEM: "Please enter a valid tenant name with no @ symbol or user included",
        associatedIdEM: "Please enter a valid support request number and hit enter/Click 'Add'",
        selectProgramPH: "Select program from the list",
        selectCaseSourcePH: "Select escalation source from the list",
        selectYourProgramEM: "Please select a program",
        selectYourProductEM: "Please select a product",
        // Escalation Submitted Review Labels
        EscalationsSubmittedReviewPageHeader: "Escalation Submitted",
        EscalationsSubmittedReviewPageSubHeader: "gethelp 2.0",
        CreateEscalationsLink: "/escalation",
        EscalationSubmissionErrorMessage: "Please send an email to GetHelp Technical Feedback team from Help section with tracking id: ",
        selectYourTimezone: "Select your timezone",
        selectCustomerPlaceHolder: "Search by company name or tpid",
        selectPartnerPlaceHolder: "Search by partner name or partnerId",
        selectValidlocation: "Please select a valid location",
        isThisEscalationForPremierCustomer: "Is this escalation for a Premier-Unified Customer or do they have a Customer Success Account Manager (CSAM)?",
        relationshipManagerCommunicationCustomer: "Would you want the relationship manager to communicate directly with the customer?",
        relationshipManagerCommunicationPartner: "Would you want the relationship manager to communicate directly with the partner?",
        TimeZones: "Pacific Standard Time (PST) : UTC-08:00, Mountain Standard Time (MST) : UTC-07:00, Central Standard Time (CST) : UTC-06:00, Eastern Standard Time (EST) : UTC-05:00, Atlantic Standard Time (AST) : UTC-04:00, Greenwich Mean Time (GMT) : UTC+00:00, Central European Time (CET) : UTC+01:00, Eastern European Time (EET) : UTC+02:00, India Standard Time (IST) : UTC+05:30, China Standard Time (CST) : UTC+08:00, Japan Standard Time (JST) : UTC+09:00, Australian Eastern Standard Time (AEST) : UTC+10:00, New Zealand Standard Time (NZST) : UTC+12:00",
        licenseQDirectHelpHeader: "Direct Help Resources",
        licenseQDirectHelpHeaderValue: `LicenseQ no longer supports licensing clarification, consulting, or interpreting licensing questions.  For guidance in those areas, consult these resources:`,
        licenseQSupportPortalHeader: "LicenseQ Support Knowledge Portal (Self-Help)",
        licenseQSupportHeaderValue: `For self-help, please visit our knowledge portal where you can find the Bot to ask licensing questions and a set of web pages that provides licensing resources.`,
        licenseQSupportText: `We can only support the following scenarios:`,
        licenseQResearchNoteText: `Note: Your GetHelp case will not be submitted until you complete this form. All the information you have already added will still be here if you need to come back and create a support case. These additional resources will open in a new tab..`,
        licenseQCompletedResearchText: "My question falls under one of the above scenarios.",
        licenseQResourceUrl: "https://microsoft.sharepoint.com/teams/SupportConnect/SitePages/LicenseQ_Home.aspx",
        licenseQScopeUrl: "https://microsoft.sharepoint.com/teams/SupportConnect/sitepages/licenseq_scope.aspx?web=1",
        licenseQScopeText: "Support Connect - LicenseQ Scope (sharepoint.com)",
        licenseQReasonToBypassLabel: "Reason to bypass LicenseQ Knowledge portal",
        licenseQSubProgramSelectionLabel: "LicenseQ Program",
        customerPartnerLabel: "Who are you advocating on behalf of?",
        eumessage: "Customer Location determines the routing of this escalation. ",
        euwarningmessage: "Learn more about the Microsoft EU/EEA Data Boundary commitment",
        licenseQEscalationReason: `Why is the issue being escalated? What steps were taken prior to this request for assistance?`,
        premiumPartnerLabel: "Is this escalation for a Premier/Advanced Partner?",
        companyNameLabel: "Company Name",
        tpIDLabel: "TPID",
        msSalesIDLabel: "MSSalesID",
        searchFilterPH: "Select Field",
        // Program Names
        psstProgramName: "Post Sales Seller Support Team - Post Sales Seller Support",
        psstParentProgramName: "Post Sales Seller Support Team",
        licenseqProgramName: "LicenseQ",
        licenseQCardHeader: "<div>Report Licensing Documentation Errors:</div><ul><li>Translation errors in Licensing Documentation</li><li>Contradictions or inconsistencies in licensing documentation</li><li>Mis-publications and inaccuracies in licensing documentation</li></ul>",
        //Tooltips
        subscriptionIDTT: "The Subscription ID of the impacted Azure Service.",
        engagementIDTT: "Please add the Success Engagement ID if your escalation is related to a Success Engagement.",
        opportunityIDTT: "Please add a valid Opportunity ID for the blocked sales opportunity. This field allows only one ID, add details to the Business Impact field if multiple opportunities are affected.",
        impactedUsersTT: "Specify the number of seats/licenses currently at risk, if the issue is not resolved in the customer's favor. This number cannot be larger than the total number of active users if an Engagement ID is entered.",
        totalUsersTT: "The number of users that are assigned for a particular Success Engagement. Auto-populated if an Engagement ID is entered.",
        impactedAmountTT: "Specify how much of the total amount is at risk if the issue is not resolved in the customer's favor. This amount cannot be larger than the total amount if an Engagement ID is entered.",
        impactedRevenueTT: "Specify the amount of revenue (in USD) Microsoft is at risk of losing if the issue is not resolved in the customer's favor. The amount cannot be larger than the total Opportunity amount.",
        totalAmountTT: "The total revenue assigned for a particular Success Engagement. Auto-populated if an Engagement ID is entered.",
        totalOpportunityAmountTT: "The Total Opportunity Amount is the amount that is assigned for a particular Opportunity ID.",
        deploymentDateTT: "Please select the planned deployment date. If you don't know this date, leave empty or add an approximate date.",
        tenantTypeTT: "STANDARD TENANT is a standard tenant type whereby the customer is hosted onthe same server farm as other customers DEDICATED Environment /ITAR is dedicated infrastructure for each customer BLACKFOREST, national cloud for Germany. A physical and logically separate Office 365 datacenter region in Germany. GALLATIN is the onshore, Sovereign-Cloud instance of Office 365 that is lecensed to 21Vianet",
        tenantNameTT: "Please enter a valid tenant URL ('x.microsoftonline.com' for example)",
        customerOrCommercialLabel: "Is this commercial or home user customer?",
        issueAreaLabel: "Issue Area",
        originalEscalationDateLabel: "Original Escalation Date",
        internalNotes: "Internal Notes",
        //Details Header form - labels
        EscalationsDetailPageHeader: "Escalation",
        EscalationsDetailPageSubHeader: "Escalation",
        casetitleLabel: "Case Title",
        typeLabel: "Type",
        submittedLabel: "Submitted",
        countryregionLabel: "Country/Region",
        lastupdatedLabel: "Last Updated",
        statusLabel: "Status",
        productprogramLabel: "Product/Program",
        ownerLabel: "Owner",
        associatedcaseLabel: "Associated ticket or IDs",
        closedLabel: "Closed",
        followupWaitmessage: "Please wait your request is being processed!",
        followUPPopUpHeaderPH: "Please click the button below if you wish to receive an update on this escalation form the support case owner.",
        reportConcernPH: "Please provide additional information as to why you wish to have this case reviewed.",
        provideAdditionalInfoPH: "Please provide additional information pertinent to the case.",
        requestUpdatePH: "The text you provide here will be provided to the Case Owner assisting on the escalation.",
        reportConcernCommercialPH: "The text you provide here will be routed to a Duty Manager or Manager that can review and assist/follow up.",
        submissionsuccessfulmessage: "Success !",
        submissionfailmessage: "Failed !",
        requestSubmitted: "Request Submitted.",
        requestNotSubmitted: "Request Not Submitted.",
        requestreceivedmessage: "Your request has been received.",
        referencenumbermessage: "Reference SR Number:",
        followupTryAgain: "Please try submitting the followup again.",
        HomepageSubHeaderLabel: "The place for you to advocate on behalf of a customer or partner experiencing a service, product, or unresolved support issue.",
        HomepageHeaderLabel: "Welcome to GetHelp,",
        noCustomerFoundLabel: "Can't find my customer in the above search",
        noPartnerFoundLabel: "Can't find my partner in the above search",
        noCustomerFoundOnDropdown: "If you can't find your customer here, please check the checkbox below and type your customer name in the textbox displayed.",
        noPartnerFoundOnDropdown: "If you can't find your partner here, please check the checkbox below and type your partner name in the textbox displayed.",
    },
};
