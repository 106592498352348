export const STAR_SEPARATOR  = '**************************************'
export const TENANTID_DESC_HEAD = 'Tenant ID(s)  provided by the Submitter: '
export const PRODUCTS_WITH_APPLICABLE_SR_NUMBER = [
  "Azure",
  "M365",
  "Dynamics 365",
  "Dynamics Legacy",
  "Dynamics PowerApps",
];
export const AZUREQUESTIONNAIRES_WITH_MANDATORY_SR_NUMBER = [
  "Azure Outage Confidence Call",
  "Azure Capacity Compute Quota",
  "Azure Capacity PaaS Quota",
  "Azure Product Confidence Call",
];